/* Styles */
body {
  font: 14px 'Helvetica Neue', Helvetica, Arial, sans-serif;
  line-height: 1.4em;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  font-smoothing: antialiased;
  font-weight: 300;
  &.home-site {
    color: #F1EFDA!important;
    background-color: #496267!important
  }
}

.navUnderline {
  color: #F1EFDA;
  text-decoration: underline;
  &:hover {
    text-decoration: underline
  }
}

@import url('https://fonts.googleapis.com/icon?family=Material+Icons');